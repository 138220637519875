<template>
  <div>
  home
  </div>
</template>

<script>
export default {
 components:{

},
 props:{

 },
 mounted(){

 },
 data(){ 
  return {

  }
 },
 watch:{

 },
 computed:{

 },
 methods:{

 }
}
</script>

<style lang="scss" scoped>

</style>